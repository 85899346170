import React from 'react'
import { ChallangesgoalsecStyles } from './Challangesgoalsec.styles'
import Casequotesec from 'components/Casequotesec/Casequotesec'
import Videoplayer from 'components/Videoplayer/Videoplayer'
import Image from 'components/ImgOptimized'

function Challangesgoalsec(props) {
  const { challangesgoalProps } = props.challangesgoaldata
  const caseQuoteProp = challangesgoalProps.caseQuoteProps
  return (
    <ChallangesgoalsecStyles className="challangesgoalsec">
      <div className="container">
        <h2>{challangesgoalProps.heading}</h2>
        <div className="row">
          <div className="left-part">
            <p>{challangesgoalProps.leftDetailsPara}</p>
            <h3>{challangesgoalProps.leftPartext}</h3>
          </div>
          <div className="right-part">
            <p>{challangesgoalProps.rightPara}</p>
          </div>
        </div>
        {challangesgoalProps.videoURL ? (
          <div className="video-blk">
            <Videoplayer videoURL={challangesgoalProps.videoURL} />
          </div>
        ) : (
          <div className="img-blk">
            {props.imageItems && (
              <Image
                fluid={props.imageItems}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 411,
                }}
              />
            )}
          </div>
        )}
        {challangesgoalProps.bottomPara ? (
          <p>{challangesgoalProps.bottomPara}</p>
        ) : (
          ' '
        )}
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </ChallangesgoalsecStyles>
  )
}

export default Challangesgoalsec
