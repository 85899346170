import styled, { keyframes } from 'styled-components'
import {
  metaFont,
  themeColor,
  primaryColor,
  black,
  md,
  lg,
  xl,
  sm,
  xll,
  sh,
} from 'config/variables'

import { images } from 'config/images'

const Gradient = keyframes`
  0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
`
export const CasestudiesscreensliderStyles = styled.div`
 margin-bottom:100px;
  ${xl(`
    margin-bottom: 80px; 
  `)} 
  ${lg(`
      margin-bottom: 60px; 
  `)} 
  ${md(`
      margin-bottom: 40px; 
  `)} 
    .testimonials-slider {
    margin-top: 50px;
    padding-bottom: 56px;
    ${xl(`
        padding-bottom: 48px;
    `)} 
    ${lg(`
        margin-top: 40px;
        padding-bottom: 37px;
    `)} 
    ${md(`
    margin-top: 30px;
    padding-bottom: 27px;
    `)} 
    .slick-list{
      .slick-track{
        display: flex;
        .slick-slide {
          display: flex;
          height: auto;
          opacity: 0.5;
          padding: 0 45px;
          transition: all 0.5s ease;
          ${xl(`
            padding: 0 30px;
          `)}
          ${lg(`
            padding: 0 20px;
          `)}
          ${md(`
            padding: 0 15px;
          `)}
          ${sm(`
            padding: 0 15px;
            opacity: 1;
          `)}
          &.slick-current {
            opacity: 1;
          }
          > div{
            height: 100%;
            width:100%;
            .testimonials-single {
              width: 867px !important;
              margin-bottom: 0;
              height: 100%;
              position: relative;
              margin: 0 auto;  
              overflow: hidden;
              ${xl(`
                max-width: 730px !important;
              `)}
              ${lg(`
                 max-width: 490px !important;
              `)}
              ${md(`
                 max-width: 320px !important;
              `)}
              ${sm(`
                max-width: 90% !important;
                margin-left:5%;
              `)}      
               .gatsby-image-wrapper {
                 width:100%;
               }
            }
          }
        }
      }
    }
    .slick-arrow {
      width: 100px;
      position: absolute;
      padding: 0;
      top: 50%;
      z-index: 9;
      height: 100px;
      box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.06),
        0 3px 5px -1px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      margin-top: -50px;
      border-radius: 50%;
      transition: all 0.3s ease;
      outline: none;
      opacity: 1;
      border: 0;
      display:flex;
      align-items:center;
      justify-content:center;
      cursor: pointer;
      ${sh(`
        width: 80px;
        height: 80px;
        margin-top: -40px;
      `)}
      ${md(`
        width: 50px;
        height: 50px;
        margin-top: -25px;
      `)}
      ${md(`
        width: 40px;
        height: 40px;
        margin-top: -20px;
      `)}
      svg {
        width: auto;
        stroke: initial;
        ${sh(`
          width: 25px;
        `)}
        ${md(`
          width: 20px;
          height: auto;
        `)}
        ${md(`
          width: 15px;
          height: auto;
        `)}
      }
      &:hover {
        opacity: 0.8;
      }
      &.slick-prev {
        left: calc((100vw - 1140px) / 2 - 100px);
        ${xll(`
          left: 20px;
        `)}
        ${xl(`
          left: 20px;
        `)}
        ${lg(`
          left: 30px;
        `)}
        ${sm(`
          left: 8px;
        `)}
      }
      &.slick-next {
        right: calc((100vw - 1140px) / 2 - 100px);
        ${xll(`
          right: 20px;
        `)}
        ${xl(`
          right: 20px;
        `)}
        ${lg(`
          right: 30px;
        `)}
        ${sm(`
          right: 8px;
        `)}
      }
    }
    .slick-dots{
      position: absolute;
      left: 0;
      right: 0;
      bottom: -60px;
      display: flex !important;
      justify-content: center;
       ${xl(`
        bottom: -50px;
      `)}  
       ${lg(`
        bottom: -40px;
      `)}  
       ${md(`
        bottom: -30px;
      `)}  
      li{
        list-style: none;
        margin: 0 8px;
        ${sm(`
          margin: 0 5px;
        `)}
        button{
          border: 0;
          cursor: pointer;
          font-size: 0;
          width: 24px;
          height: 4px;
          background: ${themeColor};
          border-radius: 2px;
          padding: 0;
          display: block-size;
          opacity: 0.2;
          transition: all 0.3s ease;      
        }
        &.slick-active{
          button{
            opacity: 1;
          }
        }
      }
    }
  }
   

`
