import React from 'react'
import { images } from 'config/images'

export const sparkStudioProps = {
  bannerProps: {
    heading: 'Spark Studio',
    bannerPara:
      'Making eLearning easy for enterprise with Virtual Reality (VR) experiences',
    BannerAlt: 'Spark Studio Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        Corporate eLearning programs are increasingly adopting Virtual Reality
        (VR) as a medium for training employees. The experiential learning
        through VR opens up possibilities in eLearning not thought of before.
        Users' knowledge is constructed and skills are improved through
        experiences and activity performed in real world scenarios. <br />{' '}
        <br />
        SPARK VR Platform makes VR content distribution easy, so enterprises can
        seamlessly deliver and manage VR learning experiences. It is a
        centralized platform for managing, distributing, and monitoring tailored
        Virtual Reality experiences for corporate training programs.
      </>
    ),
  },
  challangesgoalProps: {
    heading: 'Solving the eLearning VR distribution challenge',
    leftPartext:
      'VR experiences need to be installed locally—these are massive, heavy files that can be difficult to set up properly.',
    rightPara: (
      <>
        SweetRush is one of the biggest eLearning service providers in the
        world. With the Fortune clients like Google, Emirates, Hilton they
        identified the need to distribute and manage VR eLearning experiences on
        a global scale. <br /> <br />
        Whether it is one computer or hundreds in various locations running
        different learning programs, every new version and update can be a time
        suck. Spark VR platform solves these challenges by providing a platform
        for distributing and managing very large sized VR experiences across
        different departments and locations from one single interface.
      </>
    ),
  },
  ResultimgsectionProps: {
    heading: 'Result',
    content: (
      <>
        We collaborated with their technical team and worked as a technology
        partner. SweetRush brought expertise from eLearning domain to building
        Spark, while we provided technical know how. Our deeper partnership has
        resulted in us building extended dev teams that integrate into their
        team. <br /> <br />
        As part of the platform we built a cross platform desktop apps, mobile
        controller apps, and a cloud app that brings everything together. The
        platform was first launched at Hilton across the US for managing their
        Virtual Reality training programs for different departments.
      </>
    ),
  },
  CasePowerToolsProps: {
    mainTitle:
      'Uncompromising Cross platform apps built to harness deeper OS level features',
    CasePowerToolsDetails: [
      {
        SubTitle: '',
        para: (
          <>
            To solve the content distribution challenge, we needed to build an
            application that was cross-platform and worked across different
            operating systems and resolutions. When choosing the framework for
            building cross-platform app we have to make sure that the app isn't
            just a pretty front and can actually utilize any deeper OS level
            features. <br /> <br />
            VR files needed to be installed locally on powerful machines for
            them to run provide great experiences. Installing these experiences
            manually across different machines, operating systems, and locations
            is not feasible and can end up costing a lot of time and money.
          </>
        ),
      },
      {
        para:
          'Out of many cross-platform frameworks, we decided on Electron due to it’s adaptability and ability to harness any OS level feature we’d want around installing other files, running executables remotely from mobile devices, closing them, and managing performance of experiences.',
      },
      {
        para:
          'We used Dynamic UI components and Realm database’ offline capability to provide a reactive UI and UX. Realm utilizes memory mapping to provide high read performance. Additionally, when performing queries or traversing an object graph, Realm does not have to load all the objects until each are accessed. The faster the response, the better the performance.',
      },
    ],
    bottomPara:
      'With Electron we were able to build a responsive desktop app that made fetching, downloading, managing, scheduling, auto updating, and remote launching of VR experiences easy. ',
  },
  EngagementsecProps: {
    Title: 'Distribution',
    subTitle: <>Multi tenant architecture for white labelling the solution</>,
    para: (
      <>
        Since Spark is a high-end B2B solution used mainly by big organisations
        to manage their training programs there was a specific need for
        whitelabeling the applications with each company’s branding and style
        guide. The solution was designed in such a way that administrators and
        moderators can spin up customized apps with look and feel matching
        customers’ branding needs from the web portal. Things like typography,
        color themes, logo, messaging etc can be stylized from a web interface.
        The web portal also made release management of different versions of VR
        apps across organizations easier. A flexible multi tenant approach to
        build management and deployment ensured easy deployment across
        organizations.
      </>
    ),
  },
  appScreenSecProps: {
    mainTitle: '',
    CasePowerToolsDetails: [
      {
        Title: 'Mobile App to control the VR sessions',
        para:
          'Training programs at big organizations are normally carried out by different trainers in charge. When multiple trainees are going through training and have their VR headsets on, it becomes difficult for trainers to manage running of VR apps and experiences on multiple machines.',
      },
      {
        para: (
          <>
            We built a white-labeled mobile companion app with the sole purpose
            of managing VR apps so that users can uninterruptedly continue their
            training without interacting with the PC.
            <br /> <br />
            Server -socket communication was used for mobile app to quickly
            communicate with desktop app so that users can browse, manage,
            install, launch, suspend, reset, and stop experiences seamlessly
            from mobile app.
          </>
        ),
      },
    ],
  },
  casecontentfullwProps: {
    heading: 'Cloud architecture designed for content distribution at scale',
    para: (
      <>
        Most VR apps are bandwidth-hungry consisting of tens of files with some
        files being larger than 100 GB. When building a distribution platform
        you are faced with cloud architecture challenges that need
        considerations like high file transfer speeds, secure encrypted
        transfers, resuming of downloads, syncing between cloud and clients,
        auto-updating of apps, versioning of files and app packages, and so on.
        Now imagine this at a much higher scale over different geographies.{' '}
        <br /> <br />
        On top of this we had to ensure the system was multi-tenant and
        supported whitelabeling of the backend, as well as front end apps. We
        designed cloud architecture to have layers of network load balancers
        between their tiers used Amazon Cloudfront CDN to cache web assets, and
        used Redis distributed cache for metadata for users and popular content.
      </>
    ),
    imgCaption: 'Utilizing various AWS services at its best',
    bottomPara:
      'The platform components that serve requests were grouped into runtime clusters that can be deployed worldwide. These clusters can be scaled up to handle depending on user demand. If we identified friction in a UI, it was resolved before it even went into development. By being able to test throughout the process, we were able to reduce our risk and increase the speed for time-to-market.',
  },
  backendPortalSec: {
    trackheading: 'Secure backend and portal for easy management',
    ReliabilityinstancesDetails: [
      {
        trackbottomPara:
          'Microservices based architecture and APIs using node.js allowed for containerization of backend services for multi-tenant system. All operations like management of app packages, users, whitelabeled solutions, and analytics related to different customers and users can be tracked from the web portal. Online platforms are inherently exposed to certain security breaches specifically when large file transfers are involved. We used Symantec’s Cloud Workload protection service for in-storage protection against data-contamination.',
      },
      {
        trackbottomPara:
          'CWP for Storage helps to protect AWS S3 buckets, enabling secure adoption of containers. Signed URLs to download file can be used as simple HTTP requests, but they expire and are unique to the user who has access to the file. We also took measures like Configuring Firewalls on AWS to allow limited access, rate limited APs to certain number of requests using AWS API GATEWAY, used AUTH 2.0 Helps prevent malicious attacks orchestrated by unauthorised users, and used AWS Shield provides always-on detection and automatic inline mitigations that prevents DDOS attacks.',
      },
    ],
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'Tryg',
        para:
          'Scandinavia`s second largest general insurer brings transparency to insurance claims with telematics',
        className: 'light-peach-five',
        link: 'case-studies/tryg/',
      },
      {
        title: 'Decora',
        para: 'Meaningful innovation to drive Interior Designing virtually',
        className: 'robin-egg-blue',
        link: 'case-studies/decora-systems/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
