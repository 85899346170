import styled from 'styled-components'
import { metaFont, xl, lg, md } from 'config/variables'
export const ChallangesgoalsecStyles = styled.div`
  margin-top: 118px;
  ${xl(`
      margin-top: 100px;
  `)} 
  ${lg(`
      margin-top: 90px;
  `)} 
   ${md(`
      margin-top: 40px;
  `)} 
  .row {
    display: flex;
    justify-content: space-between;
    ${md(`
       flex-direction: column;
    `)} 
    .left-part {
      flex: 0 1 450px;
      ${xl(`
          flex: 0 0 364px;
      `)} 
      ${lg(`
          flex: 0 0 250px;
      `)} 
      ${md(`
        flex: 0 0 auto;
      `)} 
      p {
        margin-bottom:20px;
      }
      h3 {
        font-family: ${metaFont};
        line-height: 50px;
        letter-spacing: 0;
        ${md(`
          line-height: 34px;
      `)} 
      }
    }
    .right-part {
      flex: 0 1 560px;
      ${xl(`  
          flex: 1 1 auto;
          padding-left:40px;
      `)} 
      ${lg(`  
          flex: 1 1 auto;
          padding-left:40px;
      `)} 
      ${md(`
        flex-direction: column;
        padding-left:0px;
      `)} 
    }
  }
  .video-blk {
    margin: 70px 0;
    ${xl(`  
          margin: 60px 0;
    `)} 
     ${lg(`  
          margin: 50px 0;
    `)} 
    ${md(`
        margin: 10px 0 30px;
      `)} 
    video{
      width: 100%;
    }
  }
`
