import React from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { sparkStudioProps } from 'components/Props/spark-studio'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { xl, lg, md } from 'config/variables'
import Casebanner from 'components/Casebanner/Casebanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Challangesgoalsec from 'components/Challangesgoalsec/Challangesgoalsec'
import Resultimgsection from 'components/Resultimgsection/Resultimgsection'
import CasePowerTools from 'components/CasePowerTools/CasePowerTools'
import Casestudiesscreenslider from 'components/Casestudiesscreenslider/Casestudiesscreenslider'
import Engagementsec from 'components/Engagementsec/Engagementsec'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Reliabilityinstancessec from 'components/Reliabilityinstancessec/Reliabilityinstancessec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
export const SparkStudiopage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #c6d0da;
          }
        }
      }
    }
  }
  .challangesgoalsec {
    margin-bottom:110px;
    ${xl(`
      margin-bottom:90px; 
    `)}
    ${lg(`
       margin-bottom:60px; 
    `)}
    ${md(`
        margin-bottom:40px; 
    `)} 
  }
  .Resultimgsection {
    margin-bottom:120px;
    padding:100px 0;
    ${xl(`
      margin-bottom:90px;
      padding:80px 0;
    `)}
    ${lg(`
       margin-bottom:60px;
       padding:60px 0;
    `)}
    ${md(`
        margin-bottom:40px;
        padding:40px 0;
    `)}
    .container {
      .content-blk{
        flex: 0 1 546px;
        ${xl(`
          flex: 1 1 auto;
        `)}
        ${lg(`
          flex: 1 1 auto;
        `)}
      }
      
    }
    .img-blk {
      bottom:0;
      top:0px;
      display:flex;
      .imageWrap {
        flex: 0 0 1057px;
        margin:auto;
        ${xl(`
          flex: 1 1 auto;
        `)}
        ${lg(`
          flex: 1 1 auto;
        `)}
      }
    }
  }
  .casepower-section {
    margin-bottom:74px;
    ${xl(`
      margin-bottom:70px;
    `)}
    ${lg(`
       margin-bottom:60px;
    `)}
    ${md(`
        margin-bottom:40px; 
    `)}
    .powerContentRow {
      align-items: center;
      padding-bottom: 50px;
      ${lg(`
        padding-bottom:40px;
      `)}
      ${md(`
        padding-bottom:30px;
      `)}
      &:last-child {
        padding-bottom: 0px;
      }
    }
  }
  .vrSessionsSection {
     .casepower-section { 
        padding-bottom: 100px;
        margin-bottom:126px;
        position: relative;
        ${xl(`
            padding-bottom: 80px; 
            margin-bottom:100px;
        `)} 
        ${lg(`
            padding-bottom: 60px;
            margin-bottom:60px; 
        `)} 
      ${md(` 
            padding-bottom: 40px;
            margin-bottom:40px; 
        `)} 
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 729px;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(
            to bottom,
            rgba(252, 253, 255, 0),
            #f7f7f7
          );
          z-index: -1;
        } 
     }
  }
  .reliabilityinstancessec {
    .track-content {
      .row {
        margin-bottom:40px;
        :last-child {
          margin-bottom:0px;
        }
      }
    }
  }
`
const SparkStudio = props => {
  const { data } = props
  const { location } = props

  let item = [
    props.data.appWindowImg.childImageSharp.fluid,
    props.data.electronImage.childImageSharp.fluid,
    props.data.realmImage.childImageSharp.fluid,
  ]
  let caseScreenImg = [
    props.data.loungeVrScreen.childImageSharp.fluid,
    props.data.loungeVrScreen.childImageSharp.fluid,
    props.data.loungeVrScreen.childImageSharp.fluid,
  ]
  let appScreen = [
    props.data.appVrScreen.childImageSharp.fluid,
    props.data.appVrSimuScreen.childImageSharp.fluid,
  ]
  let backendImg = [
    props.data.symantecImg.childImageSharp.fluid,
    props.data.cwpAwsImg.childImageSharp.fluid,
  ]
  const itemfwidth = props.data.techArchitechDia.childImageSharp.fluid

  const { showContent } = useLazyLoad('.lazy-load-div')

  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Spark Studio"
        description=""
        keywords={[`Spark Studio`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <SparkStudiopage>
        <Casebanner
          bannerdata={sparkStudioProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={sparkStudioProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Challangesgoalsec challangesgoaldata={sparkStudioProps} {...props} />
          <Resultimgsection
            resultimgsectiondata={sparkStudioProps}
            {...props}
          />
        </div>
        {showContent && (
          <>
            <CasePowerTools
              CasePowerToolsdata={sparkStudioProps.CasePowerToolsProps}
              items={item}
            />
            <Casestudiesscreenslider
              {...props}
              rightScreenList={caseScreenImg}
            />
            <Engagementsec
              engagementsecdata={sparkStudioProps}
              {...props}
              showFullimages={true}
            />
            <div className="vrSessionsSection">
              <CasePowerTools
                CasePowerToolsdata={sparkStudioProps.appScreenSecProps}
                items={appScreen}
              />
            </div>
            <Casecontentfullwimg
              casecontentfullwdata={sparkStudioProps.casecontentfullwProps}
              fluid={itemfwidth}
            />
            <Reliabilityinstancessec
              ReliabilityinstancesData={sparkStudioProps.backendPortalSec}
              fluid={backendImg}
            />
            <Relatedcaselist
              relatedcaselistdata={sparkStudioProps}
              {...props}
            />
            <Casecontactcardinfo Casecontactcardinfodata={sparkStudioProps} />
          </>
        )}
      </SparkStudiopage>
    </Layout>
  )
}

export default SparkStudio

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/spark-studio-banner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    redbulleventscreen: file(
      relativePath: { regex: "/spark-studio-result-images@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1020) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appWindowImg: file(
      relativePath: { regex: "/spark-studio-app-window-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    electronImage: file(
      relativePath: { regex: "/sparkstudio-electron-features@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    realmImage: file(
      relativePath: { regex: "/sparkstudio-realm-features@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 541) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    loungeVrScreen: file(
      relativePath: { regex: "/sparkstudio-lounge-vr-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 867) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fihmainscreen: file(
      relativePath: { regex: "/sparkstudio-architexture-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1166) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appVrScreen: file(
      relativePath: { regex: "/sparkstudio-app-vr-screens@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 432) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appVrSimuScreen: file(
      relativePath: { regex: "/sparkstudio-app-vrsimu-screens@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 432) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    techArchitechDia: file(
      relativePath: { regex: "/sparkstudio-design-architecture@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    symantecImg: file(relativePath: { regex: "/symantec-img@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    cwpAwsImg: file(relativePath: { regex: "/cwp-aws-imgs@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/tryg-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/decora-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
